<template>
<div>
  <h5>Super Admin Dashboard</h5>
  <div class="row">
    <div class="col-md-9">
      <managerticketsoverview class="mb-2" v-if="$hasTenantModule(1)" />
      <managerpeopleoverview v-if="$hasTenantModule(3)"/>
      <!-- <myrecentfiles v-if="$hasTenantModule(2)"/> -->
      <sharedwithMeQuickAccess v-if="$hasTenantModule(2)"/>
      <ticketsByUsers v-if="$hasTenantModule(1)"/>
    </div>
    <div class="col-md-3">
      <recentnotifications class="mb-2"></recentnotifications >
      <!-- <ticketsstatslist v-if="$hasTenantModule(1)"/> -->
      <recentmessages></recentmessages>
      <recentdocsactivity v-if="$hasTenantModule(2)"/>
    </div>
  </div>
</div>

</template>

<script>
import recentnotifications from "@/components/widgets/generic/recentnotifications"
import recentmessages from '@/components/widgets/generic/recentmessages'
import managerpeopleoverview from '@/components/widgets/people/managerpeopleoverview'
import managerticketsoverview from '@/components/widgets/desk/managerticketsoverview'
// import myrecentfiles from  '@/components/widgets/myrecentfiles'
import sharedwithMeQuickAccess from '@/components/widgets/docs/quickaccess.vue'
// import ticketsstatslist from '../../components/widgets/ticketsstatslist.vue'
import recentdocsactivity from '@/components/widgets/docs/recentdocsactivity.vue'
import ticketsByUsers from '@/components/widgets/desk/ticketsByUsers.vue'

export default {
  components: { recentnotifications, recentmessages,managerpeopleoverview, managerticketsoverview, sharedwithMeQuickAccess, recentdocsactivity, ticketsByUsers }
,
created() {
  this.getDashboard()
},
methods:{
  getDashboard (){ 
     this.$http.get('/ViewAccess/goddashboard')
        .then(() => { 
        })
        .catch(() => { 
        })
  }
}
}
</script>

<style>

</style>